<template>
  <v-card class="discount-group-card">
    <v-card-title class="headline">Discount Group</v-card-title>
    <v-card-text>
      <v-form ref="editForm" v-model="valid">
        <v-row class="ma-1">
          <v-col cols="3">
            <v-autocomplete
              required
              outlined
              dense
              item-text="text"
              item-value="value"
              label="Type"
              v-model="record.Type"
              :items="Type"
              class="custom-select"
            ></v-autocomplete>
          </v-col>
          <v-col v-if="record.Type == 'S'" cols="3">
            <v-autocomplete
              required
              outlined
              dense
              :item-text="(item) => item.CardCode + '  -  ' + item.CardName"
              item-value="CardCode"
              label="Business Partner"
              :loading="!BusinessPartners.length"
              :disabled="!BusinessPartners.length"
              v-model="record.ObjCode"
              :items="BusinessPartners"
              class="custom-select"
            ></v-autocomplete>
          </v-col>
          <v-col v-if="record.Type == 'C'" cols="3">
            <v-autocomplete
              outlined
              required
              dense
              item-text="GroupName"
              item-value="GroupCode"
              label="Business Partner Group"
              v-model="record.ObjCode"
              :items="BPGroups"
              class="custom-select"
            ></v-autocomplete>
          </v-col>
          <v-col cols="2">
            <v-menu offset-y>
              <template v-slot:activator="{ on }">
                <v-text-field
                  label="Valid From"
                  readonly
                  v-on="on"
                  dense
                  v-model="record.ValidFrom"
                  outlined
                  required
                  :error-messages="dateErrorMessage"
                  class="custom-datepicker"
                ></v-text-field>
              </template>
              <v-date-picker v-model="record.ValidFrom"></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="2">
            <v-menu offset-y>
              <template v-slot:activator="{ on }">
                <v-text-field
                  required
                  dense
                  label="Valid To"
                  readonly
                  v-on="on"
                  outlined
                  v-model="record.ValidTo"
                  :error-messages="dateErrorMessage"
                  class="custom-datepicker"
                ></v-text-field>
              </template>
              <v-date-picker v-model="record.ValidTo"></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="2">
            <v-checkbox
              required
              v-model="record.ValidFor"
              :true-value="'Y'"
              :false-value="'N'"
              class="custom-checkbox"
              label="Active"
            ></v-checkbox>
          </v-col>
        </v-row>

        <div class="items-section">
          <v-btn color="primary" @click="addField" small class="mb-4">Add Items</v-btn>

          <v-container fluid class="pa-0">
            <v-row
              dense
              v-for="(field, index) in fields"
              :key="index"
              class="mb-2 item-row"
            >
              <v-col cols="12" sm="3">
                <v-autocomplete
                  outlined
                  dense
                  required
                  v-model="field.ObjKey"
                  label="ItemCode"
                  :loading="!itemsData.length"
                  :disabled="!itemsData.length"
                  :items="itemsData"
                  :item-text="(item) => item.ItemCode + '  -  ' + item.ItemName"
                  item-value="ItemCode"
                  class="custom-select"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="2">
                <v-text-field
                  outlined
                  dense
                  v-model="field.PayFor"
                  type="number"
                  label="Pay For Quantity*"
                  :rules="[(v) => !!v || 'Pay For Quantity is required']"
                  class="custom-input"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="2">
                <v-autocomplete
                  outlined
                  dense
                  item-text="text"
                  item-value="value"
                  label="Discount Type*"
                  v-model="field.DiscType"
                  :items="DiscountType"
                  :rules="[(v) => !!v || 'Discount Type is required']"
                  class="custom-select"
                ></v-autocomplete>
              </v-col>

              <v-col cols="12" sm="2" v-if="field.DiscType == 'D'">
                <v-text-field
                  outlined
                  dense
                  v-model="field.Discount"
                  type="number"
                  label="Discount %"
                  class="custom-input"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="2" v-if="field.DiscType == 'P'">
                <v-btn color="primary" @click="openItemSelectionModal(index)" small>
                  Promotional Items
                </v-btn>
              </v-col>
              <v-col cols="12" sm="1">
                <v-btn small color="warning" @click="removeField(index)" icon>
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </div>
      </v-form>
    </v-card-text>
    <v-card-actions class="justify-end">
      <v-btn color="primary" @click="save">Save</v-btn>
    </v-card-actions>

    <!-- Item Selection Modal -->
    <v-dialog v-model="itemSelectionModal" max-width="1200px">
      <v-card>
        <v-card-title class="headline">Promotional Items</v-card-title>
        <v-card-text>
          <v-btn color="success" @click="addItemLine" small class="mb-4">
            Add Promotional Item
          </v-btn>

          <v-row
            v-for="(line, lineIndex) in currentField.promo_item_lines"
            :key="lineIndex"
            class="mb-2 promo-item-row align-center"
          >
            <v-col cols="3">
              <v-autocomplete
                outlined
                dense
                v-model="line.ItemCode"
                label="Select Item*"
                :items="itemsData"
                :item-text="(item) => item.ItemCode + '  -  ' + item.ItemName"
                item-value="ItemCode"
                :rules="[(v) => !!v || 'Item is required']"
                class="custom-select"
              ></v-autocomplete>
            </v-col>

            <v-col cols="2">
              <v-text-field
                outlined
                dense
                v-model="line.ForFree"
                label="For Free Qty*"
                type="number"
                :rules="[(v) => !!v || 'Required']"
                class="custom-input"
              ></v-text-field>
            </v-col>

            <v-col cols="2">
              <v-text-field
                outlined
                dense
                v-model="line.UpTo"
                label="Max Free Qty*"
                type="number"
                :rules="[(v) => !!v || 'Required']"
                class="custom-input"
              ></v-text-field>
            </v-col>

            <v-col cols="2">
              <v-text-field
                outlined
                dense
                v-model="line.Price"
                label="Price*"
                type="number"
                :rules="[(v) => !!v || 'Required']"
                class="custom-input"
              ></v-text-field>
            </v-col>

            <v-col cols="2">
              <v-autocomplete
                dense
                outlined
                v-model="line.UomEntry"
                :items="UomEntries"
                item-text="UomName"
                item-value="id"
                label="UoM"
                @change="updatePrice"
              ></v-autocomplete>
            </v-col>

            <v-col cols="1">
              <v-btn color="warning" @click="removeItemLine(lineIndex)" small icon>
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn color="error" text @click="closeItemSelectionModal">Cancel</v-btn>
          <v-btn color="primary" @click="saveSelectedItems">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
export default {
  props: {
    initial: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      fields: [],
      itemsData: [],
      UomEntries: [],
      BusinessPartners: [],
      BPGroups: [],
      record: {},
      valid: false,
      Type: [
        { text: "All BPs", value: "A" },
        { text: "Customer Group", value: "C" },
        { text: "Specific BP", value: "S" },
        { text: "Vendor Group", value: "V" },
      ],
      ObjectType: [
        { text: "-1", value: -1 },
        { text: "Card Payment Groups", value: 10 },
        { text: "Cards", value: 2 },
      ],
      DiscountType: [
        { text: "Discount (%)", value: "D" },
        { text: "Pay for/Get for Free", value: "P" },
      ],
      itemSelectionModal: false,
      currentEditingIndex: null,
      currentField: {
        promo_item_lines: [],
      },
    };
  },
  watch: {
    initial: {
      handler: "init",
      immediate: true,
    },
  },
  computed: {
    isDateValid() {
      if (this.record.ValidFrom && this.record.ValidTo) {
        return this.record.ValidFrom <= this.record.ValidTo;
      }
      return true; // If either date is not set, consider it valid
    },
    dateErrorMessage() {
      return this.isDateValid
        ? []
        : ["Valid From date must not be greater than Valid To date"];
    },
  },
  methods: {
    init(val) {
      this.record = { ...val };
      let fields = val.edg1;
      this.fields = Array.isArray(fields) ? [...fields] : [];
    },
    addField() {
      this.fields.push({
        ObjKey: "",
        DiscType: "",
        Discount: null,
        PayFor: null,
        promo_item_lines: [],
      });
    },
    removeField(index) {
      if (index >= 0 && index < this.fields.length) {
        this.fields.splice(index, 1);
      }
    },
    closeDialog() {
      this.$emit("close");
    },
    validatePromoItems() {
      for (const field of this.fields) {
        if (field.DiscType === "P") {
          if (!field.promo_item_lines || field.promo_item_lines.length === 0) {
            return false;
          }
          for (const line of field.promo_item_lines) {
            if (!line.ItemCode || !line.ForFree || !line.UpTo || !line.Price) {
              return false;
            }
          }
        }
      }
      return true;
    },
    showMessage(message, type = "error") {
      if (this.$refs.snackbar && typeof this.$refs.snackbar.show === "function") {
        this.$refs.snackbar.show(message, type);
      } else if (typeof this.$toast === "function") {
        this.$toast[type](message);
      } else {
        console[type](message);
        alert(message);
      }
    },
    save() {
      this.$refs.editForm.validate();
      if (!this.valid) {
        this.showMessage("Please fill in all required fields.");
        return;
      }

      if (!this.isDateValid) {
        this.showMessage("Valid From date must not be greater than Valid To date.");
        return;
      }

      if (!this.validatePromoItems()) {
        this.showMessage("Please fill in all promotional item fields.");
        return;
      }

      this.record.ObjType = 4;
      let data = {
        ...this.record,
        edg1: this.fields.map((field) => ({
          ...field,
          promo_item_lines: field.promo_item_lines || [],
        })),
      };
      console.log(data);
      this.$emit("data", data);
    },
    getItems() {
      const self = this;
      this.loading = true;
      this.$store
        .dispatch("get", `/Items?$select=ItemCode,ItemName&$top=-1&$orderby=OnHand desc`)
        .then((res) => {
          self.itemsData = res.ResponseData.data;
          self.loading = false;
        })
        .catch((err) => {
          self.$refs.snackbar.show(err, "red");
        });
    },
    getBPgroups() {
      const self = this;
      this.loading = true;
      this.$store
        .dispatch("get", `/bp_group_masterdata`)
        .then((res) => {
          self.BPGroups = res.ResponseData;
          self.loading = false;
        })
        .catch((err) => {
          self.$refs.snackbar.show(err, "red");
        });
    },
    getBpartners() {
      const self = this;
      this.loading = true;
      this.$store
        .dispatch("get", `/BusinessPartners?$top=-1&$select=CardCode,CardName`)
        .then((res) => {
          self.BusinessPartners = res.ResponseData.data;
          self.loading = false;
        })
        .catch((err) => {
          self.$refs.snackbar.show(err, "red");
        });
    },
    openItemSelectionModal(index) {
      this.currentEditingIndex = index;
      this.currentField = JSON.parse(JSON.stringify(this.fields[index]));
      if (
        !this.currentField.promo_item_lines ||
        !Array.isArray(this.currentField.promo_item_lines)
      ) {
        this.currentField.promo_item_lines = [];
      }
      this.itemSelectionModal = true;
    },
    closeItemSelectionModal() {
      this.itemSelectionModal = false;
      this.currentEditingIndex = null;
      this.currentField = {
        promo_item_lines: [],
      };
    },
    saveSelectedItems() {
      if (this.currentEditingIndex !== null) {
        this.$set(
          this.fields,
          this.currentEditingIndex,
          JSON.parse(JSON.stringify(this.currentField))
        );
      }
      this.closeItemSelectionModal();
    },
    addItemLine() {
      if (!this.currentField.promo_item_lines) {
        this.$set(this.currentField, "promo_item_lines", []);
      }
      this.currentField.promo_item_lines.push({
        ItemCode: null,
        ForFree: null,
        UpTo: null,
        UomEntry: null,
        Price: null,
      });
    },
    removeItemLine(index) {
      this.currentField.promo_item_lines.splice(index, 1);
    },
    getUoms() {
      const self = this;
      this.loading = true;
      this.$store
        .dispatch("get", `/uom`)
        .then((res) => {
          self.UomEntries = res.ResponseData;

          self.loading = false;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
  },
  created() {
    this.getUoms();
    this.getBPgroups();
    this.getBpartners();
    this.getItems();
  },
};
</script>

<style scoped>
.discount-group-card {
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.headline {
  font-size: 1.5rem;
  font-weight: 500;
  color: #2c3e50;
  border-bottom: 1px solid #e0e0e0;
  padding-bottom: 16px;
}

.items-section {
  border-top: 1px solid #e0e0e0;
  padding-top: 16px;
  margin-top: 16px;
}

.item-row,
.promo-item-row {
  background-color: #f8f9fa;
  border-radius: 4px;
  padding: 8px;
  transition: background-color 0.3s ease;
}

.item-row:hover,
.promo-item-row:hover {
  background-color: #e9ecef;
}

.custom-select ::v-deep .v-input__slot,
.custom-input ::v-deep .v-input__slot,
.custom-datepicker ::v-deep .v-input__slot {
  background-color: #ffffff !important;
  border: 1px solid #ced4da !important;
  transition: border-color 0.3s ease;
}

.custom-select ::v-deep .v-input__slot:hover,
.custom-input ::v-deep .v-input__slot:hover,
.custom-datepicker ::v-deep .v-input__slot:hover {
  border-color: #80bdff !important;
}

.custom-checkbox ::v-deep .v-input--selection-controls__input {
  margin-right: 8px;
}

.v-btn {
  text-transform: none;
}
</style>
